import axios from "axios";
import {SERVER} from "../../Constants"

// API call to fetch pending photo verifications
const apiClient = axios.create({
    baseURL: SERVER,
    withCredentials: true
});

export const getPendingPhotoVerifications = async () => {
    try {
        const response = await apiClient.get('/user/getPendingPhotoVerifications');
        console.log("API response:", response); // Debugging log
        return response.data;
    } catch (error) {
        console.error("API error:", error.response); // Debugging log
        throw new Error(error.response?.data?.message || 'Failed to fetch data');
    }
};


export const verifyUser= async (verificationId) => {
    try {
        const response = await apiClient.post('/user/acceptPhotoVerification', {verificationId});
        console.log("API response:", response); // Debugging log
        return response.data;
    } catch (error) {
        console.error("API error:", error.response); // Debugging log
        throw new Error(error.response?.data?.message || 'Failed to fetch data');
    }
};

export const denyUserVerification = async (verificationId) => {
    try {
        const response = await apiClient.post('/user/denyPhotoVerification', {verificationId});
        console.log("API response:", response); // Debugging log
        return response.data;
    } catch (error) {
        console.error("API error:", error.response); // Debugging log
        throw new Error(error.response?.data?.message || 'Failed to fetch data');
    }
};

export const disableUser = async (userId) => {
    try {
        const response = await apiClient.post('/user/blockAccount', {userId});
        console.log("API response:", response); // Debugging log
        return response.data;
    } catch (error) {
        console.error("API error:", error.response); // Debugging log
        throw new Error(error.response?.data?.message || 'Failed to fetch data');
    }
};

export const enableUser = async (userId) => {
    try {
        const response = await apiClient.post('/user/unblockAccount', {userId});
        console.log("API response:", response); // Debugging log
        return response.data;
    } catch (error) {
        console.error("API error:", error.response); // Debugging log
        throw new Error(error.response?.data?.message || 'Failed to fetch data');
    }
};
