import { React, useEffect, useState } from 'react';
import '../../index.css'
import { useTranslation } from 'react-i18next';
const PHONE_IMAGE_320_JPG = 'https://mjmarry.blob.core.windows.net/assets/hero/hero_320.png'
const PHONE_IMAGE_640_JPG = 'https://mjmarry.blob.core.windows.net/assets/hero/hero_640.png'
const PHONE_IMAGE_768_JPG = 'https://mjmarry.blob.core.windows.net/assets/hero/hero_768.png'
const PHONE_IMAGE_1024_JPG = 'https://mjmarry.blob.core.windows.net/assets/hero/hero_1024.png'
const PHONE_IMAGE_1280_JPG = 'https://mjmarry.blob.core.windows.net/assets/hero/hero_1280.png'
const PHONE_IMAGE_1536_JPG = 'https://mjmarry.blob.core.windows.net/assets/hero/hero_1536.png'

export default function BehaviorComponent() {
const {t} = useTranslation();

    const BehaviourSection = () => (
        <section className="position-relative d-flex justify-content-center align-items-center vh-100 overflow-hidden">
            <picture>
                <source media="(max-width: 639px)" srcSet={PHONE_IMAGE_320_JPG} type="image/jpeg" />
                <source media="(max-width: 767px)" srcSet={PHONE_IMAGE_640_JPG} type="image/jpeg" />
                <source media="(max-width: 1023px)" srcSet={PHONE_IMAGE_768_JPG} type="image/jpeg" />
                <source media="(max-width: 1279px)" srcSet={PHONE_IMAGE_1024_JPG} type="image/jpeg" />
                <source media="(max-width: 1535px)" srcSet={PHONE_IMAGE_1280_JPG} type="image/jpeg" />
                <source media="(min-width: 1537px)" srcSet={PHONE_IMAGE_1536_JPG} type="image/jpeg" />
                <img className="img-fluid w-100" src={PHONE_IMAGE_1536_JPG} alt="Behaviour" loading="eager"
                    style={{ height: '100vh', objectFit: 'cover' }} />
            </picture>
    
            <div className=''>
                <div
                    className="content position-absolute text-center"
                     style={{top: '50%', left: '50%', transform: 'translate(-50%, -50%)'}}
                >
                    <h1 className="" style={{ fontSize: '60px', fontWeight: 'bold', textAlign: 'left', color: "whitesmoke" }}>{t('bt1')} 
                    </h1>
                    <h1 className="" style={{ fontSize: '60px', fontWeight: 'bold', textAlign: 'left', color: "whitesmoke" }}>{t('bt2')}
                    </h1>
                    <p className="text-white" style={{ fontSize: '18px', textAlign: 'justify' }}>
                    {t('bd')}
                    </p>
                    <div className="justify-content-center align-items-center ">
                        
                    </div>
                </div>
            </div>
        </section>
    );
    return (
        <>
            <BehaviourSection />
            <div className='mt-5 mx-5'>
                <section class="text-center" style={{ background: "white"}}>
                    <h1 style={{fontWeight: "bold", fontSize: "48px"}}>{t('taqwat')}</h1>
                    <div className="row d-flex flex-row justify-content-left align-items-left "
                >
                        <div className='col-md-6 col-sm-6' >
                            <p style={{fontSize: "24px",}}><span style={{ fontWeight: "bold" }}>
                            {t('taqwa1')}
                            </span></p>
                            <br></br>
                            <p style={{fontSize: "36px"}}>
                            <span style={{ color: "#e95d80" }}>
                                وَإِنَّكَ لَعَلَى‏ خُلُقٍ عَظِيمٍ‏
                            </span></p>
                            <br></br>
                            <p style={{fontSize: "24px"}}> {t('taqwa2')} </p>
                        </div>
                        <div className='col-md-6 col-sm-6'>
                           <p style={{fontSize: "24px"}}> {t('taqwa3')} </p>

                            <ul style={{fontSize: "24px", textAlign: "left"}}>
                                <li>{t('taqwa3.1')}</li>
                                <li>{t('taqwa3.2')}
                                    <span style={{ fontWeight: "bold", margin: "8px" }}>
                                    {t('every')}
                                    </span>
                                    {t('report')}</li>
                            </ul>
                        </div>
                    </div>
                </section>
            </div>
        </>
    );
}
